import * as urls from '@/config/net'
import api from '@/tools/api-tools'
import moment from 'moment'

class LineChartData {
  constructor (data) {
    this.dateTime = data.dateTime
    this.totalNumber = data.totalNumber
  }
}

class analyzeData {
  constructor () {
    this._init()
    this.dateTimeVal = ''
    this.startTime = 2000
    this.endTime = moment().year()
    this.beginDate = ''
    this.endDate = ''
    this.pageIndex = 1
    this.pageSize = 20
    this.noMoreData = false
  }

  // 初始化对象
  _init () {
    this.totalCount = null // 到店总数
    this.memberCount = null // 会员数量
    this.firstVisitorCount = null // 首次到店
    this.repeatedlyVisitorCount = null //
    this.salesAmount = null // 销售总额
    this.tradeNumber = null // 销售笔数
    this.formData = []
  }

  async getInfo () {
    await this.getTableInfo()
    await this.getChartsData()
  }

  // 获取到list数据
  _schemaFormData (data) {
    data = Object.keys(data).length > 0 ? Object.assign({}, data) : {}
    let dataList = []
    dataList = data.visitors.length > 0 ? this._schemaTime(data.visitors.slice()) : []
    if (this.pageIndex > 1) {
      if (dataList.length === 0) {
        this.noMoreData = true
      }
      this.formData = [].concat(this.formData, dataList)
    } else {
      this.formData = dataList
    }
  }

  _schemaEchartData (data) {
    this.totalCount = data.totalCount
    this.memberCount = data.memberCount
    this.tradeNumber = data.tradeNumber
    this.firstVisitorCount = data.firstVisitorCount
    this.repeatedlyVisitorCount = data.repeatedlyVisitorCount
    this.salesAmount = data.salesAmount
    this.eChartData = data.models
  }

  // 格式化时间
  _schemaTime (array) {
    if (array.length === 0) return []
    array.forEach(item => {
      item.visitTime = moment(item.visitTime).format('YYYY/MM/DD HH:mm:ss')
    })
    return array
  }

  // 获取列表数据
  getTableInfo () {
    const url = `${urls.apiUrl}/face_recognition/mobile/visitors`
    return api.post(url, {
      BeginTime: this.beginDate,
      EndTime: this.endDate,
      DateType: '',
      PageIndex: this.pageIndex,
      PageSize: this.pageSize
    }).then(res => this._schemaFormData(res.data))
  }

  // 获取e-charts表数据
  getChartsData () {
    const url = `${urls.apiUrl}/face_recognition/mobile/summary`
    return api.post(url, {
      BeginTime: this.beginDate,
      EndTime: this.endDate,
      DateType: ''
    }).then((res) => {
      const data = res.data || {}
      this._schemaEchartData(data)
    })
  }

  // 获取客流类型
  getPassengerType () {
    const array = [
      { value: this.firstVisitorCount, name: '首次到店', itemStyle: { normal: { color: '#FECD8F' } } },
      { value: this.memberCount, name: '会员', itemStyle: { normal: { color: '#FD9A61' } } },
      { value: this.repeatedlyVisitorCount, name: '多次到店', itemStyle: { normal: { color: '#FDAF71' } } }
    ].filter(item => item.value)
    return array
  }

  // 格式化图表数据
  _schemaTableData (data = []) {
    const list = []
    for (const item of data) {
      list.push(new LineChartData({
        dateTime: item.groupName,
        totalNumber: item.groupValue
      }))
    }
    return list
  }

  // 获取实时客流
  getRealTimePassengerFlow () {
    return this._schemaTableData(this.eChartData)
  }

  // 格式化时间
  formatTime (time) {
    return moment(time).format('YYYY-MM-DD')
  }

  formatSubstructTime (time) {
    return moment(time).subtract(1, 'month').format('YYYY-MM-DD')
  }

  sechmaDate (time) {
    this.beginDate = this.formatTime(time)
    this.endDate = this.formatTime(time)
  }

  // 获取用户注册时间
  getRegisterTime () {
    const url = `${urls.apiUrl}/account/current-account-info`
    return api.get(url).then(res => {
      const data = (res.data || {}).registerTime
      this.startTime = moment(this.formatTime(data)).year()
    })
  }
}

export default analyzeData
