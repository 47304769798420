<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar title="客流分析" />
    </template>
    <div class="unselectable">
      <!-- top -->
      <div class="top">
        <div class="top__left time-picker" @click="dateDialog = true">
          <i style="color: #333;font-size: 16px" class="iconfont2 icon-rili"></i>
          <div class="current-time">{{ showDateTxt }}</div>
        </div>
        <div class="top__right">实时客流</div>
      </div>
      <!-- chart -->
      <div class="chart-container">
        <canvas class="lineChart" id="lineChart" ref="lineChart"></canvas>
      </div>
      <!-- 进店顾客 -->
      <div class="custom">
        <div v-if="listItem.formData.length > 0">
          <div class="title">进店顾客</div>
          <div class="sale-item" :key="item.name + item.faceImage" v-for="(item) in listItem.formData" @click="itemActions(item)">
            <div class="item-content align-center">
              <div class="goods-icon"><img :src="item.faceImage" alt=""></div>
              <div class="goods-desc">
                <div class="text-elps left-content">
                  <div class="text-elps goods-name" :class="[item.isMember ? 'text-color primary' : 'text-color gray']">
                    {{ item.name }}
                  </div>
                  <div class="text-color gray font-small goods-count"> {{ item.visitTime | DateTime }}</div>
                </div>
                <div class="text-right text-color gray right-item">
                  <span v-if="item.isMember">查看详情</span>
                  <span @click.native="openActionSheet" v-else>录为会员</span>
                  <i class="van-icon van-icon-arrow-left back"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <van-empty v-else description="暂无客流" />
      </div>
    </div>
    <template v-slot:other>
      <van-action-sheet cancel-text="取消"
                        v-model="actionsheetShow"
                        :actions="actions"
                        @select="onSelect"
      />
      <van-popup position="bottom" get-container="body" v-model="dateDialog">
        <van-datetime-picker
          @confirm="handleDateTimeConfirm"
          @cancel="dateDialog = false"
          v-model="currentDate"
          type="date"
          :max-date="maxDate"
          :formatter="formatter"
        />
      </van-popup>
    </template>
  </page-view>
</template>
<style lang="scss" scoped>
  @import './index.scss';
</style>
<script>
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import Dayjs from 'dayjs'
import ListItem from '@/model/passenger-flow/index'
import LineEchart from '@/model/passenger-flow/lineChartInit'
import throttle from 'lodash.throttle'
import {
  DatetimePicker,
  Icon,
  Empty,
  Image,
  Popup,
  Toast,
  ActionSheet
} from 'vant'

export default {
  data () {
    const listItem = new ListItem()
    return {
      listItem,
      monthData: [],
      chart: null,
      defaultColor: '#ffffff',
      themeColor: '#ff7730',
      defaultTime: [2000, 1, 1],
      currentPage: 1,
      formModel: {
        startTime: 2000,
        endTime: 2018
      },
      timer: null,
      dateDialog: false,
      maxDate: new Date(),
      dateTimeVal: new Date(), // 默认日期
      currentDate: new Date(),
      actionsheetShow: false,
      oldParams: {}, // 旧参数
      showLineChart: true,
      memberId: '', // 会员id
      portrait: '', // 头像
      identifier: '', // 身份标识
      actions: [
        { name: '关联已有会员' },
        { name: '录入为新会员' }
      ]
    }
  },
  components: {
    NavBar,
    PageView,
    [DatetimePicker.name]: DatetimePicker,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [Image.name]: Image,
    [Popup.name]: Popup,
    [Toast.name]: Toast,
    [ActionSheet.name]: ActionSheet
  },
  created () {
  },
  async mounted () {
    this.setDefaultValue()
    await this.listItem.getRegisterTime()
    this.dateTimeChange(this.listItem.formatSubstructTime(this.defaultTime))
  },
  beforeDestroy () {
  },
  methods: {
    formatter (type, val) {
      if (type === 'year') {
        return `${val}年`
      } else if (type === 'month') {
        return `${val}月`
      } else if (type === 'day') {
        return `${val}日`
      }
      return val
    },
    // 清除参数
    clearParams () {
      this.listItem.pageIndex = this.currentPage
    },
    // 滚动刷新
    scrollLoadEvent: throttle(function () {
      this.listItem.pageIndex++
      if (this.noMoreData) return
      this.init()
    }),
    handleDateTimeConfirm (value) {
      this.dateTimeVal = value
      this.dateDialog = false
      this.init()
    },
    // 组件挂载完成后
    async init () {
      this.listItem.sechmaDate(this.dateTimeVal)
      await this.listItem.getInfo()
      this.initLineChart()
    },
    // 初始化折线图表
    initLineChart () {
      const chartSetting = {
        id: 'lineChart',
        pixelRatio: window.devicePixelRatio, // 指定分辨率
        width: window.innerWidth + 30
      }
      const options = {
        chartSetting: chartSetting,
        data: this.listItem.getRealTimePassengerFlow()
      }
      if (this.listItem.getRealTimePassengerFlow().length > 0) {
        this.showLineChart = true
        this.$nextTick((_) => {
            new LineEchart(options) // eslint-disable-line
        })
      } else {
        this.showLineChart = false
      }
    },
    initPieChart () {
      const chartSetting = {
        id: 'pieChart',
        pixelRatio: window.devicePixelRatio // 指定分辨率
      }
      const options = {
        chartSetting: chartSetting,
        data: this.listItem.getPassengerType()
      }
      if (this.listItem.getPassengerType().length > 0) {
          new PieEchart(options) // eslint-disable-line
      }
    },
    // 切换日期
    dateTimeChange (data) {
      this.clearParams()
      this.dateTimeVal = data
      this.init()
    },
    // 设置日期插件初始值
    setDefaultValue () {
      const year = Dayjs().get('year')
      const month = Dayjs().get('month') + 1
      const day = Dayjs().get('date')
      this.defaultTime = [year, month, day]
    },
    setParams (data) {
      this.memberId = data.memberId
      this.identifier = data.identifier
      this.portrait = data.faceImage
    },
    openActionSheet (item) {
      this.setParams(item)
      this.actionsheetShow = true
    },
    closeActionSheet () {
      this.actionsheetShow = false
    },
    // 查看会员详情
    openMemberDetail (item) {
      if (!window.$bridge) return
      this.closeActionSheet()
        $bridge.memberInfo.openMemberDetail(item.memberId) // eslint-disable-line
    },
    // 关联已有会员
    connectMember () {
      if (!window.$bridge) return
      this.closeActionSheet()
        $bridge.memberInfo.connectMember(this.identifier, this.portrait) // eslint-disable-line
    },
    // 录入新会员
    addMember () {
      if (!window.$bridge) return
      this.closeActionSheet()
        $bridge.memberInfo.addNewMember(this.identifier, this.portrait) // eslint-disable-line
    },
    itemActions (item) {
      if (item.isMember) return this.openMemberDetail(item)
      this.openActionSheet(item)
    },
    // actionSheet事件
    onSelect (item) {
      const { name } = item
      if (name === '关联已有会员') {
        this.connectMember()
      } else if (name === '录入为新会员') {
        this.addMember()
      }
    }
  },
  computed: {
    // 当前月图表数据
    currentData () {
      const data = this.monthData.filter(item => {
        if (item.isChecked) return item
      })
      return data[0] || {}
    },
    showDateTxt () {
      if (Dayjs(this.dateTimeVal).isSame(new Date(), 'day')) {
        return '今天'
      } else {
        return Dayjs(this.dateTimeVal).format('YYYY-MM-DD')
      }
    }
  }
}
</script>
