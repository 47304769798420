import F2 from '@antv/f2'

class LineEchart {
  constructor (options) {
    this.lineChart = null
    this.themeColor = '#fff'
    this._init(options)
  }

  _init (options) {
    this.options = options.chartSetting
    this.data = options.data
    this.initChartData(options)
  }

  initChartData (options) {
    this.initChart(options)
    this.setTheme()
    this.clearTitle()
    this.setAxis()
    this.changeInterval()
    this.renderData(this.data)
    this.toolTip()
  }

  initChart (options) {
    this.lineChart = new F2.Chart({ ...options.chartSetting })
  }

  setTheme () {
    F2.Global.setTheme({
      guide: {
        line: {
          stroke: this.themeColor,
          opacity: '1',
          fill: {
            lineWidth: 100
          }
        },
        grid: {
          stroke: this.themeColor,
          fill: this.themeColor,
          opacity: '1'
        }
      }
    })
  }

  // 不显示图例说明
  clearTitle () {
    this.lineChart.legend(false)
  }

  // 设置坐标
  setAxis () {
    this.lineChart.axis('dateTime', {
      line: { stroke: 'transparent' },
      grid: {
        opacity: '.2'
      },
      label: (text, index, total) => {
        const cfg = {
          fill: '#fff',
          fontSize: 12,
          opacity: '.3'
        }
        if (index === 0) {
          cfg.textAlign = 'left'
        } else if (index === total - 1) {
          cfg.textAlign = 'right'
        }
        return cfg
      }
    })
    this.lineChart.axis('totalNumber', false)
  }

  // 显示提示信息
  toolTip () {
    this.lineChart.tooltip({
      showItemMarker: false,
      onShow (ev) {
        const items = ev.items
        items[0].name = items[0].title
      }
    })
  }

  changeInterval () {
    this.lineChart.area().position('dateTime*totalNumber').color('#eee')
    this.lineChart.line().position('dateTime*totalNumber').color('#fff')
  }

  renderData (data) {
    const defs = {
      dateTime: {
        tickCount: 6,
        range: [0, 1]
      }
    }
    this.lineChart.source(data, defs)
    this.lineChart.render()
  }
}

export default LineEchart
